import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import CartIcon from "../../../../assets/icons/CartIcon";
import DownloadIcon from "../../../../assets/icons/DownloadIcon";
import ProfileIcon from "../../../../assets/icons/ProfileIcon";
import ShareIcon from "../../../../assets/icons/ShareIcon";
import UploadIcon from "../../../../assets/icons/UploadIcon";
import { ThemeContext } from "../../../../components/App";
import DownloadForms from "../../../../components/common/DownloadFolders";
import { useEvent } from "../../../../Store/event";
import { useUser } from "../../../../Store/user";
import { useFolder } from "../../../../Store/folder";
import DotsOptions from "../../../../assets/icons/DotsOptions";
import "./galleryHeading.css";
import DialogWrapper from "../../../../components/common/Dialog";
import EmailPopUp from "../../../../components/common/EmailPopUp";
import Share from "../../../../components/common/Share";
import { useFavourites } from "../../../../Store/favourites";
import DownArrow from "../../../../assets/icons/DownArrow";
import FavoritiesDialog from "../FavoritiesDialog";
import { useCart } from "../../../../Store/cart";
import LogoutIcon from "../../../../assets/icons/LogoutIcon";
import {
  faceSearchDownload,
  favouriteDownload,
} from "../../../../apis/download";
import { imageAfterFaceSearch } from "../../../../apis/facesearch";
import Text from "../../../../components/common/Text";
import Button from "../../../../components/common/Button";
import {
  ENVIRONMENT,
  EVENT_WHERE_DROPBOX_NEEDED,
  EVENT_WHERE_GUEST_UPLOAD_NOT_REQUIRED,
  EVENT_WHERE_PIN_NOT_REQUIRED,
  EVENT_WITH_DIRECT_FACE_REC,
  MY_PHOTOS,
  PHOTOGRAPHER_WITH_DIRECT_FACE_REC,
  PHOTOGRAPHER_WITH_FACE_REC_ACCESS,
  STAGING_ENVIRONMENT_KEY,
  VIEW_SEARCH_ID,
} from "../../../../constants";
import AddImages from "../../../../components/common/AddImages";
import { useUploader } from "../../../../Store/upload";
import FaceSearchPopUp from "../FaceSearchPopUp";
import HeartOutlineIcon from "../../../../assets/icons/HeartOutlinedIcon";

var Base64 = {
  _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
  encode: function (e) {
    var t = "";
    var n, r, i, s, o, u, a;
    var f = 0;
    e = Base64._utf8_encode(e);
    while (f < e.length) {
      n = e.charCodeAt(f++);
      r = e.charCodeAt(f++);
      i = e.charCodeAt(f++);
      s = n >> 2;
      o = ((n & 3) << 4) | (r >> 4);
      u = ((r & 15) << 2) | (i >> 6);
      a = i & 63;
      if (isNaN(r)) {
        u = a = 64;
      } else if (isNaN(i)) {
        a = 64;
      }
      t =
        t +
        this._keyStr.charAt(s) +
        this._keyStr.charAt(o) +
        this._keyStr.charAt(u) +
        this._keyStr.charAt(a);
    }
    return t;
  },
  decode: function (e) {
    var t = "";
    var n, r, i;
    var s, o, u, a;
    var f = 0;
    e = e.replace(/[^A-Za-z0-9\+\/\=]/g, "");
    while (f < e.length) {
      s = this._keyStr.indexOf(e.charAt(f++));
      o = this._keyStr.indexOf(e.charAt(f++));
      u = this._keyStr.indexOf(e.charAt(f++));
      a = this._keyStr.indexOf(e.charAt(f++));
      n = (s << 2) | (o >> 4);
      r = ((o & 15) << 4) | (u >> 2);
      i = ((u & 3) << 6) | a;
      t = t + String.fromCharCode(n);
      if (u != 64) {
        t = t + String.fromCharCode(r);
      }
      if (a != 64) {
        t = t + String.fromCharCode(i);
      }
    }
    t = Base64._utf8_decode(t);
    return t;
  },
  _utf8_encode: function (e) {
    e = e.replace(/\r\n/g, "\n");
    var t = "";
    for (var n = 0; n < e.length; n++) {
      var r = e.charCodeAt(n);
      if (r < 128) {
        t += String.fromCharCode(r);
      } else if (r > 127 && r < 2048) {
        t += String.fromCharCode((r >> 6) | 192);
        t += String.fromCharCode((r & 63) | 128);
      } else {
        t += String.fromCharCode((r >> 12) | 224);
        t += String.fromCharCode(((r >> 6) & 63) | 128);
        t += String.fromCharCode((r & 63) | 128);
      }
    }
    return t;
  },
  _utf8_decode: function (e) {
    var t = "";
    var n = 0;
    var r = 0;
    var c1 = 0;
    var c2 = 0;
    var c3 = 0;
    while (n < e.length) {
      r = e.charCodeAt(n);
      if (r < 128) {
        t += String.fromCharCode(r);
        n++;
      } else if (r > 191 && r < 224) {
        c2 = e.charCodeAt(n + 1);
        t += String.fromCharCode(((r & 31) << 6) | (c2 & 63));
        n += 2;
      } else {
        c2 = e.charCodeAt(n + 1);
        c3 = e.charCodeAt(n + 2);
        t += String.fromCharCode(
          ((r & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
        );
        n += 3;
      }
    }
    return t;
  },
};

const GalleryHeading = ({
  onFolderClickHandler,
  isMobileView,
  onFolderOptionsClickHandler,
  privateMode,
  setPrivateMode,
  changeGallery,
  showFavouriteImages,
  isFoldersDrawerVisible,
  showFaceSearchImages,
  scanPopupOpen = false,
}) => {
  const theme = useContext(ThemeContext);
  const eventDetails = useEvent().response;
  const { user, setUser } = useUser();
  let { folder } = useFolder();
  const route = useLocation();
  const { currentFav, setCurrentFav, favList } = useFavourites();
  const { cartCnt } = useCart();
  const navigate = useNavigate();
  const params = useParams();
  const { isUploading, guestUploadedImages } = useUploader();

  const [downloadPopUp, setDownloadPopUp] = useState(false);
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [sharePopUp, setSharePopUp] = useState(false);
  const [newListPopUp, setNewListPopUp] = useState(false);
  const [faceRecPopUp, setFaceRecPopUp] = useState(false);
  const [actionOptionsVisible, setActionOptionsVisible] = useState(false);
  const [showFavDd, setShowFavDd] = useState(false);
  const [searchId, setSearchId] = useState();
  const [showNewImagePopUp, setShowNewImagePopUp] = useState(false);
  const [uploadPopUp, setUploadPopUp] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [imgArr, setimgArr] = useState([]);

  const iconsColor = theme === "dark" ? "white" : "#222";

  const showMoreActions = (e) => {
    setActionOptionsVisible(!actionOptionsVisible);
    e.stopPropagation();
  };

  const getNewImageCount = async () => {
    if (
      !faceRecPopUp &&
      user.search_id &&
      !showFavouriteImages &&
      !showFaceSearchImages &&
      !isFoldersDrawerVisible &&
      !scanPopupOpen &&
      !EVENT_WHERE_DROPBOX_NEEDED.includes(eventDetails.data.id)
    ) {
      try {
        const res = await imageAfterFaceSearch(user.search_id);
        res.data > 0 ? setShowNewImagePopUp(true) : setShowNewImagePopUp(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    getNewImageCount();
  }, [user.search_id]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setShowFavDd(false);
          setActionOptionsVisible(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    setSearchId(params.searchId ? params.searchId : "");
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 1024;
  let downloadFolderLength = emptyFolderImagesCheck(eventDetails.data.folders);
  // console.log('downloadFolderLength:'+downloadFolderLength.length)
  function emptyFolderImagesCheck(data) {
    return data
      .map((element) => {
        if (element.imagesCount && element.isDownloadAble) {
          return element.id;
        }
      })
      .filter((notUndefined) => notUndefined !== undefined);
  }
  function logout() {
    let initialUser = {
      email: "",
      password: "",
      accessToken: "",
      jwt: "",
      isValid: false,
      isAdmin: false,
      error: "",
      pin: eventDetails.pin || "",
      fname: "",
      lname: "",
      phone: "",
      id: 0,
      search_id: "",
      images: [],
      isPhotoGrapher: false,
    };
    if (
      PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
        eventDetails.photographer.id
      ) ||
      PHOTOGRAPHER_WITH_FACE_REC_ACCESS.includes(
        eventDetails.photographer.id
      ) ||
      EVENT_WITH_DIRECT_FACE_REC.includes(eventDetails.data.id)
    ) {
      initialUser.pin = user.pin;
      initialUser.fname = user.fname;
      initialUser.lname = user.lname;
      initialUser.email = user.email;
    }
    if (EVENT_WHERE_PIN_NOT_REQUIRED.includes(eventDetails.data.id)) {
      initialUser.pin = user.pin;
    }
    /** As we are reloading no need to set states */
    setUser(initialUser);
    localStorage.removeItem("userFor" + eventDetails.data.id);
    window.Toast.fire({
      icon: "success",
      title: "Logged out successfully.",
    });
    // changeGallery(false);
    setActionOptionsVisible(false);
    // removeMyPhotosFolder();
    // setGuestUploadedImages([]);
    // setShowUploader(false);
    if (showFaceSearchImages || !!route?.search?.includes("searchId")) {
      if (
        PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
          eventDetails.photographer.id
        ) ||
        PHOTOGRAPHER_WITH_FACE_REC_ACCESS.includes(
          eventDetails.photographer.id
        ) ||
        EVENT_WITH_DIRECT_FACE_REC.includes(eventDetails.data.id)
      ) {
        navigate(
          `/facerec/${eventDetails.data.slug}?pin=${user.pin}&userId=${user.fname}`
        );
      } else if (EVENT_WHERE_PIN_NOT_REQUIRED.includes(eventDetails.data.id)) {
        navigate(`/facerec/${eventDetails.data.slug}?pin=${user.pin}`);
      } else {
        navigate(`/facerec/${eventDetails.data.slug}`);
      }
    } else if (
      isFoldersDrawerVisible ||
      showFavouriteImages ||
      route.search.includes(VIEW_SEARCH_ID)
    ) {
      navigate(
        `/view/${
          eventDetails.data.slug
        }/${eventDetails.data.folders[0].slug.toLowerCase()}`
      );
    }
    window.location.reload();
  }

  async function favouriteImageDownloads(e) {
    if (
      (showFavouriteImages &&
        eventDetails.data.downloadBulkImageSize != "both") ||
      (showFaceSearchImages &&
        eventDetails.data.downloadBulkImageSize != "both")
    ) {
      let downloadSize;
      if (eventDetails.data.downloadBulkImageSize == "original") {
        downloadSize = 0;
      }
      if (eventDetails.data.downloadBulkImageSize == "optimized") {
        downloadSize = 1;
      }
      if (showFavouriteImages) {
        var res = await favouriteDownload(
          eventDetails.data.id,
          user.email,
          downloadSize,
          params.listId,
          user.accessToken,
          "favourites"
        );
      }
      if (showFaceSearchImages) {
        var res = await faceSearchDownload(
          eventDetails.data.id,
          user.email,
          downloadSize,
          user.search_id,
          user.accessToken,
          "face_search"
        );
      }
      if (!res.error) {
        // console.log(res)
        window.Toast.fire({
          icon: "success",
          title: res.response.message
            ? res.response.message
            : "Download in process, please wait for it to complete you wil receive an email shortly.",
        });
        localStorage.setItem("downloadInitiatedFrom", window.location.href);
        if (res.response?.downloadRequestId) {
          // let url = window.location.protocol + '//' +window.location.host+'/downloadNow/'+eventDetails.data.slug+'/'+Base64.encode(res.response.dl.toString())
          try {
            const url =
              window.location.protocol +
              "//" +
              window.location.host +
              "/downloadNow/" +
              eventDetails.data.slug +
              "/" +
              res.response.downloadRequestId;
            window.open(url, "_blank").focus();
          } catch (error) {
            console.log("Issue while opening browser in new window", error);
          }
        }
      } else {
        window.Toast.fire({
          icon: "error",
          title: res.e.message[0],
        });
      }
      if (res.response.dl) {
        // let url = window.location.protocol + '//' +window.location.host+'/downloadNow/'+eventDetails.data.slug+'/'+Base64.encode(res.response.dl.toString())
        let url =
          window.location.protocol +
          "//" +
          window.location.host +
          "/downloadNow/" +
          eventDetails.data.slug +
          "/" +
          res.response.dl;
        // console.log(url);
        window.open(url, "_blank")?.focus();
      }
    } else {
      setDownloadPopUp(e);
    }
  }

  useEffect(() => {
    if (showFavouriteImages) {
      if (currentFav?.count) {
        /** Random data so that download option becomes visible */
        setimgArr(["1", "2"]);
      } else setimgArr([]);
    } else {
      if (folder.id && folder.images && folder.images.length > 0) {
        setimgArr(folder.images);
      }
    }
  }, [showFavouriteImages, currentFav, folder]);

  const uploadInProgressToast = () => {
    window.Toast.fire({
      icon: "error",
      title: "Please wait for the current upload to finish.",
    });
  };

  const guestUploadJSX =
    eventDetails.data.isGuestUploadEnabled &&
    eventDetails.photographer.isGuestUploadEnabled ? (
      <button
        onClick={() => {
          user.accessToken && user.id != 0
            ? isUploading
              ? uploadInProgressToast()
              : setUploadPopUp(true)
            : setEmailPopUp(true);
        }}
        title="Guest Upload"
        className={`flex items-center justify-end w-[30px] md:w-[40px]  relative h-[30px] ${
          isMobile ? "" : "mt-[7px]"
        } mb-[6px]  bg-theme-light dark:bg-dark-gray rounded-[10px]`}
      >
        <UploadIcon color={iconsColor} height="16" width="16" />
      </button>
    ) : (
      <></>
    );

  const downloadJSX = !!eventDetails.data.isDownloadChargeable ? (
    <></>
  ) : (
    <button
      title="Download Images"
      onClick={() => {
        user.accessToken ? favouriteImageDownloads(true) : setEmailPopUp(true);
      }}
      className={`flex items-center  justify-end w-[30px] md:w-[40px] relative  h-[30px]  ${
        isMobile ? "" : "mt-[7px]"
      } mb-[6px]  bg-theme-light dark:bg-dark-gray rounded-[10px]`}
    >
      <DownloadIcon height="16" width="16" color={iconsColor} />
    </button>
  );

  const logoutJSX = (
    <button
      onClick={logout}
      className={`flex items-center justify-end w-[30px] md:w-[40px]  relative h-[30px] ${
        isMobile ? "" : "mt-[7px]"
      } mb-[6px]  bg-theme-light dark:bg-dark-gray rounded-[10px] text-base font-medium cursor-pointer`}
      title="Logout"
    >
      <LogoutIcon height="16" width="16" color={iconsColor} />
    </button>
  );

  const shareJSX = !!eventDetails.data.showImageShare && (
    <button
      title="Share the event"
      className="flex items-center relative h-[30px] justify-end w-[30px] md:w-[40px] mt-[7px] mb-[6px]   bg-theme-light dark:bg-dark-gray rounded-[10px]"
      onClick={() => {
        setSharePopUp(true);
        setActionOptionsVisible(false);
      }}
    >
      <ShareIcon height="16" width="16" color={iconsColor} />
    </button>
  );

  const faceSearchLoggedOut = (
    <button
      className="flex items-center"
      onClick={() => navigate(`/facerec/${eventDetails.data.slug}`)}
    >
      <span style={{ marginBottom: 3 }}>
        <ProfileIcon color={iconsColor} />
      </span>
      <span className="ml-1 text-base font-medium">Face Search</span>
    </button>
  );

  // console.log('imgArr:'+imgArr.length)
  // console.log(isFoldersDrawerVisible)
  return (
    <div
      className={`text-dark-gray1 dark:text-light-gray  ${
        route.pathname.includes("facerec") ? "mb-10" : ""
      }`}
    >
      <div className="flex justify-between sm:pt-[1.875rem] pt-8 items-center">
        <div className="max-w-[55%] md:max-w-[50%]">
          <div
            className={`text-sm md:text-xl lg:text-3xl ${
              isMobile ? "overflow-hidden  whitespace-nowrap text-ellipsis" : ""
            } font-medium uppercase leading-6`}
          >
            {eventDetails.data.name.replace("-", " ")}
          </div>
          <div
            className={`mt-[2px] text-xs md:text-sm ${
              isMobile ? "overflow-hidden  whitespace-nowrap text-ellipsis" : ""
            }`}
          >
            {eventDetails.photographer.brandName}
          </div>
        </div>
        {!isMobileView && !eventDetails.data.isPublished && (
          <>
            <div className="flex ml-3 p-1 text-indigo-50 text-sm bg-slate rounded-lg items-center h-[30px] sm:h-full">
              <div className="p-1 text-indigo-50 bg-slate rounded">Draft</div>
            </div>
            <div className="flex-1"></div>
          </>
        )}
        {!isMobile && (
          <>
            <div className="justify-between hidden lg:flex" title="Face Search">
              {route.pathname.includes("facerec") ||
              route?.search?.includes("searchId") ||
              params.listId ||
              !eventDetails.data.isPublished ? (
                <></>
              ) : (
                <button
                  className="flex items-center"
                  onClick={() => setFaceRecPopUp(true)}
                >
                  <span style={{ marginBottom: 3 }}>
                    <ProfileIcon color={iconsColor} />
                  </span>
                  <span className="ml-2 text-base font-medium">
                    Face Search
                  </span>
                </button>
              )}
              {!showFavouriteImages && !isFoldersDrawerVisible && (
                <button
                  className="flex items-center ml-4 relative"
                  title="Select the Favourite List to add or remove photos from that list"
                  onClick={() => {
                    !user.accessToken
                      ? setEmailPopUp(true)
                      : setShowFavDd(!showFavDd);
                  }}
                >
                  <HeartOutlineIcon height="20" width="20" color={iconsColor} />
                  <span
                    className={`mx-2 text-base  font-medium ${
                      isFoldersDrawerVisible ? "text-[#00b7b8]" : ""
                    }`}
                  >
                    {currentFav?.name || "Favourites"}
                  </span>
                  {user.email && (
                    <DownArrow rotate={showFavDd} color={iconsColor} />
                  )}
                  {showFavDd && (
                    <div
                      ref={wrapperRef}
                      className="absolute p-5 bg-theme-light dark:bg-dark-gray shadow-dark-input-bottom-right dark:shadow-dark-input w-[250px] right-[10px] top-[40px] z-[3] rounded-lg"
                    >
                      <ul className="text-left break-all">
                        {favList.map((f, i) => (
                          <li
                            title="Select the favourite list as default list"
                            key={i}
                            onClick={() => {
                              setCurrentFav(f);
                              setShowFavDd(false);
                              changeGallery(false);
                              window.Toast.fire({
                                icon: "success",
                                title: f.name + " set as favorite list.",
                              });
                            }}
                            className="justify-start mt-2 text-sm"
                          >
                            {f.name} ({f.count} photos)
                          </li>
                        ))}
                        <li
                          title="View all list"
                          className="flex justify-start mt-4 border-t-2 pt-2 text-sm"
                          onClick={(e) => {
                            onFolderClickHandler(e);
                            navigate(
                              `/favourites/${eventDetails.data.slug}${
                                searchId ? "?searchId=" + searchId : ""
                              }`
                            );
                            setShowFavDd(!showFavDd);
                          }}
                        >
                          View All Lists
                        </li>
                        <li
                          title="Create a new list"
                          className="flex justify-start mt-2 text-sm"
                          onClick={() => {
                            setNewListPopUp(true);
                            setShowFavDd(!showFavDd);
                          }}
                        >
                          Create New
                        </li>
                      </ul>
                    </div>
                  )}
                </button>
              )}
              {PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
                eventDetails.photographer.id
              ) ||
              EVENT_WHERE_GUEST_UPLOAD_NOT_REQUIRED.includes(
                eventDetails.data.id
              ) ? (
                <></>
              ) : (
                guestUploadJSX
              )}
              {!!eventDetails.data.showPrintStore &&
                !!eventDetails.photographer.showPrintStore &&
                !EVENT_WHERE_DROPBOX_NEEDED.includes(eventDetails.data.id) && (
                  //eventDetails.country == "India" &&
                  <a
                    title="Cart"
                    onClick={() => {
                      !user.accessToken
                        ? setEmailPopUp(true)
                        : cartCnt > 0
                        ? window.location.href.includes("algomage.com")
                          ? navigate(
                              `/view/${eventDetails.data.slug}/add-to-cart?at=${
                                user.accessToken
                              }&view=${
                                window.location.href.includes("facerec")
                                  ? "1"
                                  : "2"
                              }`
                            )
                          : ENVIRONMENT === STAGING_ENVIRONMENT_KEY
                          ? navigate(
                              `/view/${eventDetails.data.slug}/add-to-cart`
                            )
                          : window.location.assign(
                              `//${
                                eventDetails.photographer.subDomain
                              }.algomage.com/view/${
                                eventDetails.data.slug
                              }/add-to-cart?at=${user.accessToken}&view=${
                                window.location.href.includes("facerec")
                                  ? "1"
                                  : "2"
                              }`
                            )
                        : window.Toast.fire({
                            icon: "error",
                            title: "Please add items to proceed.",
                          });
                    }}
                    className="flex items-center justify-end w-[30px] md:w-[40px] relative h-[30px] mt-[7px] mb-[6px]   bg-theme-light dark:bg-dark-gray rounded-[10px] cursor-pointer"
                  >
                    {cartCnt > 0 ? (
                      <div className="h-4 w-4 absolute -top-1 -right-2 rounded-full bg-black dark:bg-white text-white dark:text-black text-xs z-10 flex items-center justify-center">
                        {cartCnt}
                      </div>
                    ) : (
                      <></>
                    )}
                    <CartIcon height="20" width="20" color={iconsColor} />
                  </a>
                )}
              {!showFavouriteImages &&
                !isFoldersDrawerVisible &&
                params.folderName !== MY_PHOTOS.slug &&
                !showFaceSearchImages &&
                shareJSX}
              {((!isFoldersDrawerVisible &&
                eventDetails?.data?.downloadBulkImageSize &&
                downloadFolderLength.length > 0 &&
                imgArr.length > 0) ||
                (showFaceSearchImages &&
                  eventDetails?.data?.downloadBulkImageSize &&
                  (!!user.images.length || !!guestUploadedImages.length))) &&
                downloadJSX}
              {user.accessToken && logoutJSX}
            </div>
          </>
        )}
        {isMobile && (
          <>
            {/* MOBILE */}
            <div className="items-center justify-between flex lg:hidden relative">
              {route.pathname.includes("facerec") ||
              route?.search?.includes("searchId") ||
              params.listId ||
              !eventDetails.data.isPublished ? (
                ""
              ) : (
                <button
                  className="flex items-center justify-end  relative w-[30px] md:w-[40px] h-[30px] mb-[6px]  bg-theme-light dark:bg-dark-gray rounded-[10px]"
                  title="Face Search"
                  onClick={() => setFaceRecPopUp(true)}
                >
                  <ProfileIcon height="16" width="16" color={iconsColor} />
                </button>
              )}
              {!showFavouriteImages && !isFoldersDrawerVisible && (
                <>
                  <button
                    title="Favourite list"
                    className="flex items-center justify-end relative w-[30px] md:w-[40px] h-[30px] mb-[6px] bg-theme-light dark:bg-dark-gray rounded-[10px]"
                    onClick={() => {
                      !user.accessToken
                        ? setEmailPopUp(true)
                        : setShowFavDd(!showFavDd);
                    }}
                  >
                    <HeartOutlineIcon
                      height="20"
                      width="20"
                      color={iconsColor}
                    />
                    {favList.length > 0 && showFavDd && (
                      <div
                        ref={wrapperRef}
                        className="absolute p-5 bg-theme-light dark:bg-dark-gray shadow-dark-input-bottom-right dark:shadow-dark-input w-[250px] sm-right-[10px] top-[40px] z-[3] right-[-190%]  md:right-[-220%] rounded-lg"
                      >
                        <ul className="text-left break-all">
                          {favList.map((f, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                setCurrentFav(f);
                                setShowFavDd(false);
                                changeGallery(false);
                                window.Toast.fire({
                                  icon: "success",
                                  title: f.name + " set as favorite list.",
                                });
                              }}
                              className="flex justify-start mt-2 text-xs "
                            >
                              {f.name} ({f.count} photos)
                            </li>
                          ))}
                          <li
                            className="flex justify-start mt-4 border-t-2 pt-2 text-xs"
                            onClick={(e) => {
                              onFolderClickHandler(e);
                              navigate(
                                `/favourites/${eventDetails.data.slug}${
                                  searchId ? "?searchId=" + searchId : ""
                                }`
                              );
                            }}
                          >
                            View All Lists
                          </li>
                          <li
                            className="flex justify-start mt-2 text-xs"
                            onClick={() => {
                              setNewListPopUp(true);
                            }}
                          >
                            Create New
                          </li>
                        </ul>
                      </div>
                    )}
                  </button>
                </>
              )}
              {!!eventDetails.data.showPrintStore &&
                !!eventDetails.photographer.showPrintStore &&
                !EVENT_WHERE_DROPBOX_NEEDED.includes(eventDetails.data.id) && (
                  //eventDetails.country == "India" &&
                  <a
                    title="Cart"
                    onClick={() => {
                      !user.accessToken
                        ? setEmailPopUp(true)
                        : cartCnt > 0
                        ? window.location.href.includes("algomage.com")
                          ? navigate(
                              `/view/${eventDetails.data.slug}/add-to-cart?at=${
                                user.accessToken
                              }&view=${
                                window.location.href.includes("facerec")
                                  ? "1"
                                  : "2"
                              }`
                            )
                          : ENVIRONMENT === STAGING_ENVIRONMENT_KEY
                          ? navigate(
                              `/view/${eventDetails.data.slug}/add-to-cart`
                            )
                          : window.location.assign(
                              `//${
                                eventDetails.photographer.subDomain
                              }.algomage.com/view/${
                                eventDetails.data.slug
                              }/add-to-cart?at=${user.accessToken}&view=${
                                window.location.href.includes("facerec")
                                  ? "1"
                                  : "2"
                              }`
                            )
                        : window.Toast.fire({
                            icon: "error",
                            title: "Please add items to proceed.",
                          });
                    }}
                    className="flex items-center cursor-pointer justify-end  relative w-[30px] md:w-[40px] h-[30px] mb-[6px]  bg-theme-light dark:bg-dark-gray rounded-[10px]"
                  >
                    {cartCnt > 0 ? (
                      <div className="h-4 w-4 absolute -top-1 -right-2 rounded-full bg-black dark:bg-white text-white dark:text-black text-xs z-10 flex items-center justify-center">
                        {cartCnt}
                      </div>
                    ) : (
                      <></>
                    )}
                    <CartIcon height="20" width="20" color={iconsColor} />
                  </a>
                )}
              {isFoldersDrawerVisible && user.accessToken && (
                <>
                  {PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
                    eventDetails.photographer.id
                  ) ||
                  EVENT_WHERE_GUEST_UPLOAD_NOT_REQUIRED.includes(
                    eventDetails.data.id
                  ) ? (
                    <></>
                  ) : (
                    guestUploadJSX
                  )}
                  {logoutJSX}
                </>
              )}
              {params.listId && (
                <>
                  {PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
                    eventDetails.photographer.id
                  ) ||
                  EVENT_WHERE_GUEST_UPLOAD_NOT_REQUIRED.includes(
                    eventDetails.data.id
                  ) ? (
                    <></>
                  ) : (
                    guestUploadJSX
                  )}
                  {!!imgArr.length &&
                    eventDetails?.data?.downloadBulkImageSize &&
                    downloadJSX}
                  {logoutJSX}
                </>
              )}
              {showFaceSearchImages && (
                <>
                  {PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
                    eventDetails.photographer.id
                  ) ||
                  EVENT_WHERE_GUEST_UPLOAD_NOT_REQUIRED.includes(
                    eventDetails.data.id
                  ) ? (
                    <></>
                  ) : (
                    guestUploadJSX
                  )}
                </>
              )}

              {!isFoldersDrawerVisible && !params.listId && (
                <a
                  className="ml-[10px] mt-[-7px] cursor-pointer"
                  onClick={showMoreActions}
                >
                  <DotsOptions color={theme === "dark" ? "white" : "black"} />
                </a>
              )}
              {actionOptionsVisible && (
                <div
                  ref={wrapperRef}
                  className="absolute pb-3 bg-theme-light dark:bg-dark-gray shadow-dark-input-bottom-right dark:shadow-dark-input w-[119px] right-[10px] top-[40px] z-[3] rounded-lg"
                >
                  {!showFavouriteImages &&
                    !isFoldersDrawerVisible &&
                    params.folderName !== MY_PHOTOS.slug &&
                    !!eventDetails.data.showImageShare &&
                    !showFaceSearchImages && (
                      <div
                        className="flex flex-row px-5 border-b-[0.5px] border-b-secondary-500 cursor-pointer text-dark-gray1 dark:text-light-gray text-xs pt-3 pb-3"
                        onClick={() => {
                          user.accessToken
                            ? setSharePopUp(true)
                            : setEmailPopUp(true);
                          setActionOptionsVisible(false);
                        }}
                      >
                        Share
                      </div>
                    )}
                  {((!isFoldersDrawerVisible &&
                    eventDetails?.data?.downloadBulkImageSize &&
                    downloadFolderLength.length > 0 &&
                    imgArr.length > 0) ||
                    (showFaceSearchImages &&
                      eventDetails?.data?.downloadBulkImageSize &&
                      (!!user.images.length ||
                        !!guestUploadedImages.length))) &&
                    !!!eventDetails.data.isDownloadChargeable && (
                      <div
                        className="flex px-5  border-b-[0.5px] border-b-secondary-500 flex-row  cursor-pointer text-dark-gray1 dark:text-light-gray text-xs pt-3 pb-3"
                        onClick={() => {
                          user.accessToken
                            ? favouriteImageDownloads(true)
                            : setEmailPopUp(true);
                          setActionOptionsVisible(false);
                        }}
                      >
                        Download
                      </div>
                    )}
                  {/* /** Disabling guest upload for bharat jodo yatra */}
                  {!showFaceSearchImages ? (
                    PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
                      eventDetails.photographer.id
                    ) ||
                    EVENT_WHERE_GUEST_UPLOAD_NOT_REQUIRED.includes(
                      eventDetails.data.id
                    ) ? (
                      <></>
                    ) : !eventDetails.data.isGuestUploadEnabled ||
                      !eventDetails.photographer.isGuestUploadEnabled ? (
                      <></>
                    ) : (
                      <div
                        onClick={() => {
                          user.accessToken && user.id != 0
                            ? isUploading
                              ? uploadInProgressToast()
                              : setUploadPopUp(true)
                            : setEmailPopUp(true);
                        }}
                        title="Guest Upload"
                        className="flex flex-row px-5  border-b-[0.5px] border-b-secondary-500  cursor-pointer text-dark-gray1 dark:text-light-gray text-xs pt-3 pb-3"
                      >
                        Guest Upload
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                  {user.accessToken && (
                    <span
                      onClick={logout}
                      className="flex px-5 pt-3 cursor-pointer text-xs"
                    >
                      Logout
                    </span>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <DialogWrapper
        modal="downloadModal"
        open={downloadPopUp}
        close={() => {
          setDownloadPopUp(false);
        }}
        description={
          showFaceSearchImages || showFavouriteImages
            ? ""
            : "Select the folders you wish to download"
        }
        className="mt-[0.375rem] text-base"
        title={
          showFaceSearchImages || showFavouriteImages
            ? "Download Photos"
            : "Download Folders"
        }
        closeClassname="self-baseline"
      >
        <DownloadForms
          showFavouriteImages={showFavouriteImages}
          showFaceSearchImages={showFaceSearchImages}
          close={(e) => {
            setDownloadPopUp(e);
          }}
        />
      </DialogWrapper>
      <DialogWrapper
        open={emailPopUp}
        close={() => {
          setEmailPopUp(false);
        }}
        description=""
        title="Login"
      >
        <EmailPopUp
          close={(e) => {
            setEmailPopUp(e);
          }}
        />
      </DialogWrapper>
      <DialogWrapper
        open={sharePopUp}
        close={() => {
          setSharePopUp(false);
        }}
        description=""
        title="Share"
      >
        <Share shareUrl={window.location.href} />
      </DialogWrapper>
      <FaceSearchPopUp
        getter={faceRecPopUp}
        setter={setFaceRecPopUp}
        user={user}
      />
      <DialogWrapper
        open={showNewImagePopUp}
        close={() => {
          setShowNewImagePopUp(false);
        }}
      >
        <div>
          <Text
            className="dark:text-light-gray text-dark-gray text-center  "
            as="div"
          >
            New image have been added since your last face search
          </Text>
          <div className="flex justify-center flex-row-reverse gap-4 mt-6">
            <Button
              onClick={() => {
                setShowNewImagePopUp(false);
                setTimeout(() => setFaceRecPopUp(true), 250);
              }}
              text="Update Results"
            ></Button>
            <Button
              bg="bg-transparent dark:text-light-gray text-dark-gray border-2"
              onClick={() => setShowNewImagePopUp(false)}
              text="Cancel"
            ></Button>
          </div>
        </div>
      </DialogWrapper>
      <DialogWrapper
        open={uploadPopUp}
        close={() => {
          setUploadPopUp(false);
        }}
        description=""
        title="Add Images"
      >
        <AddImages setShowImageUploader={setUploadPopUp} />
      </DialogWrapper>
      <FavoritiesDialog
        open={newListPopUp}
        close={() => {
          setNewListPopUp(false);
        }}
      />
    </div>
  );
};

export default GalleryHeading;
